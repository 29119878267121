import { forwardRef } from "react";

function UptimelineLogo(props, ref) {
  return (
    <svg
      width="145"
      height="26"
      viewBox="0 0 145 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <rect
        y="5.12891"
        width="145"
        height="2.84375"
        rx="1.42188"
        fill="#139A43"
      />
      <path
        d="M17.4885 1.19336V13.2031C17.4885 14.4811 17.2042 15.6364 16.6357 16.6689C16.0756 17.693 15.2229 18.5055 14.0773 19.1064C12.9403 19.7074 11.5062 20.0078 9.77517 20.0078C7.31438 20.0078 5.43908 19.3815 4.14929 18.1289C2.8595 16.8763 2.2146 15.2174 2.2146 13.1523V1.19336H6.14762V12.5557C6.14762 14.0876 6.46159 15.1624 7.08951 15.7803C7.71744 16.3981 8.6466 16.707 9.87699 16.707C10.7425 16.707 11.4426 16.5589 11.9772 16.2627C12.5202 15.9665 12.919 15.5094 13.1736 14.8916C13.4282 14.2738 13.5555 13.4867 13.5555 12.5303V1.19336H17.4885Z"
        fill="white"
      />
      <path
        d="M29.6567 5.29395C31.2604 5.29395 32.5545 5.91602 33.5388 7.16016C34.5316 8.4043 35.028 10.2282 35.028 12.6318C35.028 14.2399 34.7946 15.5941 34.3279 16.6943C33.8612 17.7861 33.2163 18.6113 32.3932 19.1699C31.5701 19.7285 30.624 20.0078 29.5548 20.0078C28.8675 20.0078 28.2778 19.9232 27.7856 19.7539C27.2935 19.5762 26.8734 19.3519 26.5255 19.0811C26.1776 18.8018 25.8764 18.5055 25.6218 18.1924H25.4182C25.486 18.5309 25.537 18.8779 25.5709 19.2334C25.6048 19.5889 25.6218 19.9359 25.6218 20.2744V26H21.7397V5.56055H24.8963L25.4436 7.40137H25.6218C25.8764 7.02051 26.1861 6.66927 26.551 6.34766C26.9159 6.02604 27.3529 5.77214 27.862 5.58594C28.3796 5.39128 28.9778 5.29395 29.6567 5.29395ZM28.4093 8.3916C27.7305 8.3916 27.1916 8.53125 26.7928 8.81055C26.394 9.08984 26.1012 9.50879 25.9146 10.0674C25.7364 10.626 25.6388 11.3327 25.6218 12.1875V12.6064C25.6218 13.5205 25.7067 14.2949 25.8764 14.9297C26.0546 15.5645 26.3473 16.0469 26.7546 16.377C27.1704 16.707 27.7389 16.8721 28.4602 16.8721C29.0542 16.8721 29.5421 16.707 29.924 16.377C30.3058 16.0469 30.5901 15.5645 30.7768 14.9297C30.9719 14.2865 31.0695 13.5036 31.0695 12.5811C31.0695 11.193 30.8531 10.1478 30.4204 9.44531C29.9876 8.74284 29.3172 8.3916 28.4093 8.3916Z"
        fill="white"
      />
      <path
        d="M44.2305 16.9229C44.6548 16.9229 45.0663 16.8805 45.4651 16.7959C45.8724 16.7113 46.2755 16.6055 46.6743 16.4785V19.3604C46.2585 19.5465 45.7409 19.6989 45.1215 19.8174C44.5105 19.9443 43.8402 20.0078 43.1104 20.0078C42.2619 20.0078 41.4982 19.8724 40.8193 19.6016C40.149 19.3223 39.6186 18.8441 39.2283 18.167C38.8464 17.4814 38.6555 16.5293 38.6555 15.3105V8.46777H36.7972V6.83008L38.9355 5.53516L40.0556 2.53906H42.5376V5.56055H46.5216V8.46777H42.5376V15.3105C42.5376 15.8522 42.6904 16.2585 42.9959 16.5293C43.3098 16.7917 43.7214 16.9229 44.2305 16.9229Z"
        fill="white"
      />
      <path
        d="M53.4457 5.56055V19.7539H49.5636V5.56055H53.4457ZM51.511 0C52.0881 0 52.5845 0.135417 53.0002 0.40625C53.416 0.66862 53.6239 1.16374 53.6239 1.8916C53.6239 2.611 53.416 3.11035 53.0002 3.38965C52.5845 3.66048 52.0881 3.7959 51.511 3.7959C50.9255 3.7959 50.4249 3.66048 50.0091 3.38965C49.6018 3.11035 49.3982 2.611 49.3982 1.8916C49.3982 1.16374 49.6018 0.66862 50.0091 0.40625C50.4249 0.135417 50.9255 0 51.511 0Z"
        fill="white"
      />
      <path
        d="M74.2437 5.29395C75.8559 5.29395 77.0736 5.70866 77.8967 6.53809C78.7282 7.35905 79.144 8.67936 79.144 10.499V19.7539H75.2492V11.4639C75.2492 10.4482 75.0752 9.68229 74.7273 9.16602C74.3794 8.64974 73.8406 8.3916 73.1108 8.3916C72.0841 8.3916 71.3543 8.75977 70.9216 9.49609C70.4888 10.224 70.2725 11.2692 70.2725 12.6318V19.7539H66.3903V11.4639C66.3903 10.7868 66.314 10.2197 66.1612 9.7627C66.0085 9.30566 65.7751 8.96289 65.4612 8.73438C65.1472 8.50586 64.7442 8.3916 64.252 8.3916C63.5307 8.3916 62.9622 8.57357 62.5464 8.9375C62.1391 9.29297 61.8464 9.82194 61.6682 10.5244C61.4985 11.2184 61.4136 12.069 61.4136 13.0762V19.7539H57.5315V5.56055H60.4972L61.019 7.37598H61.2354C61.5239 6.88509 61.8845 6.4873 62.3173 6.18262C62.7586 5.87793 63.2422 5.65365 63.7683 5.50977C64.2944 5.36589 64.829 5.29395 65.3721 5.29395C66.4158 5.29395 67.2983 5.46322 68.0196 5.80176C68.7493 6.1403 69.3093 6.66504 69.6997 7.37598H70.0433C70.4676 6.64811 71.0658 6.11914 71.838 5.78906C72.6187 5.45898 73.4206 5.29395 74.2437 5.29395Z"
        fill="white"
      />
      <path
        d="M89.0084 5.29395C90.3237 5.29395 91.4565 5.54785 92.4068 6.05566C93.3572 6.55501 94.0912 7.28288 94.6088 8.23926C95.1264 9.19564 95.3853 10.3636 95.3853 11.7432V13.6221H86.2082C86.2506 14.7139 86.5773 15.5729 87.1883 16.1992C87.8077 16.8171 88.6647 17.126 89.7594 17.126C90.6673 17.126 91.4989 17.0329 92.2541 16.8467C93.0093 16.6605 93.7857 16.3812 94.5834 16.0088V19.0049C93.8791 19.3519 93.1408 19.6058 92.3687 19.7666C91.605 19.9274 90.6758 20.0078 89.5812 20.0078C88.1556 20.0078 86.8913 19.7454 85.7882 19.2207C84.6935 18.696 83.8323 17.8962 83.2043 16.8213C82.5849 15.7464 82.2752 14.3923 82.2752 12.7588C82.2752 11.0999 82.5552 9.72038 83.1152 8.62012C83.6838 7.51139 84.4729 6.68197 85.4827 6.13184C86.4925 5.57324 87.6677 5.29395 89.0084 5.29395ZM89.0339 8.04883C88.2787 8.04883 87.6507 8.29004 87.1501 8.77246C86.6579 9.25488 86.3737 10.0124 86.2973 11.0449H91.745C91.7365 10.4694 91.6304 9.95736 91.4268 9.50879C91.2316 9.06022 90.9346 8.70475 90.5358 8.44238C90.1455 8.18001 89.6448 8.04883 89.0339 8.04883Z"
        fill="white"
      />
      <path d="M102.424 19.7539H98.5419V0H102.424V19.7539Z" fill="white" />
      <path
        d="M110.392 5.56055V19.7539H106.51V5.56055H110.392ZM108.457 0C109.034 0 109.531 0.135417 109.946 0.40625C110.362 0.66862 110.57 1.16374 110.57 1.8916C110.57 2.611 110.362 3.11035 109.946 3.38965C109.531 3.66048 109.034 3.7959 108.457 3.7959C107.872 3.7959 107.371 3.66048 106.955 3.38965C106.548 3.11035 106.344 2.611 106.344 1.8916C106.344 1.16374 106.548 0.66862 106.955 0.40625C107.371 0.135417 107.872 0 108.457 0Z"
        fill="white"
      />
      <path
        d="M122.56 5.29395C124.079 5.29395 125.301 5.70866 126.226 6.53809C127.151 7.35905 127.613 8.67936 127.613 10.499V19.7539H123.731V11.4639C123.731 10.4482 123.544 9.68229 123.171 9.16602C122.806 8.64974 122.229 8.3916 121.44 8.3916C120.252 8.3916 119.442 8.79362 119.009 9.59766C118.576 10.4017 118.36 11.5612 118.36 13.0762V19.7539H114.478V5.56055H117.443L117.965 7.37598H118.182C118.487 6.88509 118.865 6.4873 119.314 6.18262C119.773 5.87793 120.277 5.65365 120.829 5.50977C121.389 5.36589 121.966 5.29395 122.56 5.29395Z"
        fill="white"
      />
      <path
        d="M137.478 5.29395C138.793 5.29395 139.926 5.54785 140.876 6.05566C141.826 6.55501 142.56 7.28288 143.078 8.23926C143.596 9.19564 143.854 10.3636 143.854 11.7432V13.6221H134.677C134.72 14.7139 135.046 15.5729 135.657 16.1992C136.277 16.8171 137.134 17.126 138.228 17.126C139.136 17.126 139.968 17.0329 140.723 16.8467C141.478 16.6605 142.255 16.3812 143.052 16.0088V19.0049C142.348 19.3519 141.61 19.6058 140.838 19.7666C140.074 19.9274 139.145 20.0078 138.05 20.0078C136.625 20.0078 135.36 19.7454 134.257 19.2207C133.163 18.696 132.301 17.8962 131.673 16.8213C131.054 15.7464 130.744 14.3923 130.744 12.7588C130.744 11.0999 131.024 9.72038 131.584 8.62012C132.153 7.51139 132.942 6.68197 133.952 6.13184C134.962 5.57324 136.137 5.29395 137.478 5.29395ZM137.503 8.04883C136.748 8.04883 136.12 8.29004 135.619 8.77246C135.127 9.25488 134.843 10.0124 134.766 11.0449H140.214C140.206 10.4694 140.1 9.95736 139.896 9.50879C139.701 9.06022 139.404 8.70475 139.005 8.44238C138.615 8.18001 138.114 8.04883 137.503 8.04883Z"
        fill="white"
      />
    </svg>
  );
}

export default forwardRef(UptimelineLogo);
